import React, { useCallback, useRef, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { assetImages, maxFileSize } from "utils/constant";
import { Input, Button, Upload, message } from "antd";
import { useUsersGoBack } from "hooks/useUserGoBack";

const { TextArea } = Input;

const ContactDetail = ({ contact, onConfirm }) => {
  const { t } = useTranslation();
  const [disabled, setDisableConfirmBtn] = useState(true);
  const [files, setFiles] = useState([]);
  const inputRef = useRef();
  const usersGoBack = useUsersGoBack();

  const handleChangeButtonState = useCallback(
    (event) => {
      const hasText = event.target.value.toString().length > 0;

      if (hasText && disabled) {
        setDisableConfirmBtn(false);
      }

      if (!hasText && !disabled) {
        setDisableConfirmBtn(true);
      }
    },
    [disabled]
  );

  const handleConfirm = useCallback(() => {
    onConfirm({
      parent_id: contact.id,
      title: contact.title,
      content: inputRef.current.resizableTextArea.textArea.value,
      attachments: files.map((file) => file.originFileObj) || [],
    });
  }, [onConfirm, files, contact]);

  const handleSelectFiles = (info) => {
    const { fileList } = info;
    setFiles(
      fileList.filter((file) => {
        return file.size <= maxFileSize && file.type && file.type !== "";
      })
    );
  };

  return (
    <div className="w-full h-full max-h-full flex flex-col bg-white rounded-lg overflow-y-auto p-6">
      <div className="flex items-center mb-6">
        <div
          onClick={usersGoBack}
          className="flex justify-between mr-2 cursor-pointer"
        >
          <img src={assetImages.rightArrow} alt="<" width={36} />
        </div>
        <span className="text-primary capitalize text-xl font-inter font-bold">
          {contact.title}
        </span>
      </div>
      {/* User Contact */}
      <div
        className={`rounded-lg mb-8 ${
          contact.reply && contact.reply.length > 0
            ? "p-2 border border-secondary"
            : ""
        }`}
      >
        <div className="flex items-center font-inter mb-3">
          <p className="mr-8">
            {t("contacts.detail.id")}: {contact.id}
          </p>
          <p className="mr-8">
            {t("contacts.detail.username")}: {contact.user_name}
          </p>
          <p>
            {t("contacts.detail.dateTime")}:{" "}
            {moment(new Date(contact.date_time)).format("YYYY-MM-DD HH:mm")}
          </p>
        </div>
        <div className="flex items-center font-inter mb-3">
          <p>
            <b>{t("contacts.detail.title")}:</b> {contact.title}
          </p>
        </div>
        <div className="flex items-center font-inter">
          <p>
            <b>{t("contacts.detail.contents")}:</b> {contact.content}
          </p>
        </div>
      </div>
      {/* Reply */}
      {!!contact.reply && contact.reply.length > 0 && (
        <React.Fragment>
          <div className={""}>
            <div className="flex items-center font-inter mb-3">
              {/* <p className="mr-8">ID: {contact.id}</p> */}
              <p className="mr-8">
                {t("contacts.detail.enrichUsername")}:{" "}
                {contact.reply[contact.reply.length - 1].user_name}
              </p>
              <p>
                {t("contacts.detail.dateTime")}:{" "}
                {moment(
                  new Date(contact.reply[contact.reply.length - 1].date_time)
                ).format("YYYY-MM-DD HH:mm")}
              </p>
            </div>
            <div className="flex items-center font-inter mb-3">
              <p>
                <b>{t("contacts.detail.title")}:</b>{" "}
                {contact.reply[contact.reply.length - 1].title}
              </p>
            </div>
            <div className="flex items-center font-inter">
              <p>
                <b>{t("contacts.detail.contents")}:</b>{" "}
                {contact.reply[contact.reply.length - 1].content}
              </p>
            </div>
          </div>
        </React.Fragment>
      )}

      <TextArea
        ref={inputRef}
        className={
          "bg-gray-700 hover:!bg-gray-700 focus:!bg-gray-700 hover:!border-gray-500 focus:!border-gray-500 my-8 !min-h-64"
        }
        rows={8}
        placeholder={t("contacts.detail.inputPlaceholder")}
        onChange={handleChangeButtonState}
      />
      <div className="flex justify-between">
        <Upload
          listType="picture"
          accept=".png, .jpg"
          maxCount={3}
          multiple
          fileList={files}
          onChange={handleSelectFiles}
          beforeUpload={(file) => {
            if (file.size > maxFileSize) {
              message.error(t("contacts.detail.fileRules.maxFileSize"));
            }
            if (!file.type || file.type === "") {
              message.error(t("contacts.detail.fileRules.fileTypes"));
            }
            return false;
          }}
        >
          <Button size={"large"}>{t("contacts.detail.attachFile")}</Button>
        </Upload>
        <Button
          type="primary"
          size={"large"}
          disabled={disabled}
          onClick={handleConfirm}
        >
          {t("contacts.detail.confirm")}
        </Button>
      </div>
      <ul className="list-disc ml-6 mt-3 mb-8">
        <li>{t("contacts.detail.fileRules.maxFile")}</li>
        <li>{t("contacts.detail.fileRules.maxFileSize")}</li>
        <li>{t("contacts.detail.fileRules.fileTypes")}</li>
      </ul>
    </div>
  );
};

export default ContactDetail;
