export const emailValidator = (value, t) => {
  if (!value) {
    return Promise.reject(t("login.emailEmpty"));
  }

  if (value.length > 255) {
    return Promise.reject(t("login.emailFormat"));
  }

  const [localPart, domainPart] = value.split("@");

  if (!localPart || !domainPart || localPart.length > 64 || domainPart.length > 190) {
    return Promise.reject(t("login.emailFormat"));
  }

  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,}$/i;
  if (!emailRegex.test(value)) {
    return Promise.reject(t("login.emailFormat"));
  }

  return Promise.resolve();
};
