import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import countReducer from "./slices/countSlice";
import authReducer from "./slices/authSlide";
import userReducer from "./slices/userSlice";

const rootReducer = combineReducers({
  count: countReducer,
  auth: authReducer,
  user: userReducer,
});

export const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [logger];

export const store = configureStore({
  reducer: persistedReducer,
  preloadedState: {},
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middlewares),
});

export const persistor = persistStore(store);
export const getStoreInstance = () => {
  if (!store) {
    return null;
  }
  return store;
};
