import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import React from "react";
import { UserOutlined, ContactsOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const AdminSider = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sideBars = [
    {
      name: t("sider.users"),
      url: "/users",
      // icon: <UserOutlined className="!text-gray-900 !text-xl" />,
    },
    // {
    // name: t("sider.contact"),
    // url: "/#",
    // TODO: Block this code; it's out of scope.
    // url: "/contacts",
    // icon: <ContactsOutlined className="!text-gray-900 !text-xl" />,
    // },
  ];

  function extractBasePath(pathname) {
    const parts = pathname.split("/");
    return parts.length > 1 ? `/${parts[1]}` : "/";
  }

  const items = sideBars.map((item) => {
    return {
      key: item.url,
      icon: item.icon,
      label: (
        <div
          className={`text-base font-inter font-semibold ${
            extractBasePath(location.pathname) === item.url
              ? "!text-secondary"
              : "!text-gray-950"
          }`}
          onClick={() => {
            if (location.pathname === item.url) {
              navigate(0);
            } else {
              navigate(item.url);
            }
          }}
        >
          {item.name}
        </div>
      ),
    };
  });

  return (
    <Menu
      className="!w-full"
      mode={"inline"}
      selectedKeys={[location.pathname, extractBasePath(location.pathname)]}
      items={items}
    />
  );
};

export default AdminSider;
