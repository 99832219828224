import React from "react";
import { useEffect, useMemo, useState, useCallback } from "react";
import UserManagement from "components/Users";
import UserService from "services/UserService";
import handleError from "utils/handleError";
import CreateUserModal from "components/Users/CreateUserModal";

const UserManagementPage = () => {
  const [usersData, setUsersData] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await UserService.getUsers({ searchKey, page });
        setUsersData(data);
        setLoading(false);
      } catch (err) {
        handleError(err);
      }
    }

    fetchData();
  }, [searchKey, page]);

  const dataSource = useMemo(
    () => (usersData ? usersData.users : []),
    [usersData]
  );

  const handleChangePage = useCallback(
    (newPage) => {
      setPage(newPage);
      setLoading(true);
    },
    [setPage]
  );

  const handleSearch = useCallback(
    (search) => {
      setPage(1);
      setSearchKey(search.trim());
      if (search !== searchKey) {
        setLoading(true);
      }
    },
    [searchKey]
  );

  return (
    <React.Fragment>
      <UserManagement
        users={dataSource}
        loading={loading}
        onSearch={handleSearch}
        pagination={
          usersData.pagination && {
            currentPage: usersData.pagination.page,
            totalCount: usersData.pagination.total_count,
            pageSize: usersData.pagination.per_page,
            onChangePage: handleChangePage,
          }
        }
      />
    </React.Fragment>
  );
};

export default UserManagementPage;
