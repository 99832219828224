import { Layout } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import crossBrowserListener from "utils/reduxPersistListener";
import { persistConfig, store } from "storage/store";

const LoginLayout = () => {
  const location = useLocation();

  window.addEventListener(
    "storage",
    crossBrowserListener(store, persistConfig, location.pathname)
  );

  return (
    <Layout className="w-svw h-svh bg-white flex items-center justify-center">
      <Outlet />
    </Layout>
  );
};

export default LoginLayout;
