import { Layout } from "antd";

const ErrorLayout = () => {
  return (
    <Layout className="w-svw h-svh bg-white flex items-center justify-center">
      Not Found
    </Layout>
  );
};

export default ErrorLayout;
