import { useEffect, useState } from "react";
import handleError from "utils/handleError";
import { useParams, useNavigate } from "react-router-dom";
import BasicInformation from "components/Users/UserDetail/BasicInformation";
import { getUserInformationByIdActionCenter } from "storage/slices/userSlice";
import { useAppDispatch, useAppSelector } from "hooks";

const BasicInformationPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const basicInfo = useAppSelector((state) => state.user.data.user);
  const error = useAppSelector((state) => state.user.meta.error);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData(userId) {
      try {
        dispatch(getUserInformationByIdActionCenter({ userId }));
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData(id);
  }, [id]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        if (error) navigate("/404");
      }, 500);
    }
  }, [error]);

  console.log(error);

  return <BasicInformation basicInfo={basicInfo} userId={id} />;
};

export default BasicInformationPage;
