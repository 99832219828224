import axios from "axios";
import { apiPrefix, storage } from "utils/constant";
import handleError from "utils/handleError";
import { getStoreInstance } from "storage/store";
import {
  logoutActionCenter,
  refreshTokenActionCenter,
} from "storage/slices/authSlide";

const API_URL = process.env.REACT_APP_API_URL;

const ApiService = async (configuration = {}) => {
  const instance = axios.create({
    // TODO: Please ensure to update the baseURL once the API is ready.
    baseURL: `${API_URL}${apiPrefix}`,
    headers: {
      "content-type": "application/json",
    },
    ...configuration,
  });
  // Add a request interceptor
  instance.interceptors.request.use(
    function (config) {
      const reduxStore = getStoreInstance();
      // Do something before request is sent

      if (!!reduxStore) {
        const { data } = reduxStore.getState().auth;
        if (data[storage.ACCESS_TOKEN]) {
          config.headers.Authorization = `Bearer ${data[storage.ACCESS_TOKEN]}`;
        }
      }

      return config;
    },
    function (error) {
      // Do something
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async function (error) {
      const reduxStore = getStoreInstance();
      const { data } = reduxStore.getState().auth;

      function logoutAction() {
        //Call Logout API here
        reduxStore.dispatch(logoutActionCenter());
      }

      const originalConfig = error.config;
      if (error.response?.status === 401) {
        //Logout when http status code is 401
        logoutAction();
      } else if (
        !!data[storage.REFRESH_TOKEN] &&
        error.response?.status === 461
      ) {
        try {
          const { data } = reduxStore.getState().auth;
          reduxStore.dispatch(
            refreshTokenActionCenter({
              refresh_token: data[storage.REFRESH_TOKEN],
            })
          );
          originalConfig.headers.Authorization = `Bearer ${
            data[storage.ACCESS_TOKEN]
          }`;
          return await axios(originalConfig);
        } catch (_error) {
          logoutAction();
          handleError(_error);
        }
      }

      return Promise.reject(error);
    }
  );
  return instance;
};

export default ApiService;
