import { assetImages, indexPageUrl } from "utils/constant";
import { useTranslation } from "react-i18next";
import { Button, Popover } from "antd";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { logoutActionCenter } from "storage/slices/authSlide";

const AdminHeader = ({ onCreateAdminAccount }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.data.profile);
  const navigate = useNavigate();

  const handleGoToHomePage = () => {
    navigate(indexPageUrl || "/");
  };

  const handleLogout = useCallback(async () => {
    dispatch(logoutActionCenter());
  }, [dispatch]);

  return (
    <div className="px-5 h-full flex items-center justify-between">
      <span
        onClick={handleGoToHomePage}
        className="font-inter font-bold text-2xl text-secondary cursor-pointer"
      >
        enrich
      </span>
      <div className="flex text-center">
        {/* <Button
          type="text"
          className="flex items-center cursor-pointer py-4"
          onClick={onCreateAdminAccount}
        >
          <img className="h-8" src={assetImages.addUser} alt="add-user-admin" />
          <p className="font-inter font-bold text-base text-gray-900 pl-2">
            {t("header.createAdminAccountBtn")}
          </p>
        </Button> */}
        <Popover
          placement="bottom"
          className={"cursor-pointer"}
          content={
            <Button
              className={"w-24"}
              type="primary"
              danger
              onClick={handleLogout}
            >
              Log out
            </Button>
          }
        >
          <div className="pl-4 font-inter text-sm font-normal text-gray-900 flex items-center">
            <p>{user?.user_name}</p>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default AdminHeader;
