import { message as antdMessage } from "antd";

const handleError = (error) => {
  if (error?.response?.status === 401) return;

  let message =
    error.response?.data?.error ||
    error.response?.data?.message ||
    "Something went wrong";
  if (typeof error === "string") message = error;

  antdMessage.error(message);
};

export default handleError;
