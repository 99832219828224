import React from "react";
import { Form, Input, Button } from "antd";
import Modal from "components/common/Modal";
import { useTranslation } from "react-i18next";

const CreateAdminModal = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation();

  const rules = {
    username: [{ required: true, message: t("validate.required") }],
    email: [
      { type: "email", message: t("validate.email") },
      { required: true, message: t("validate.required") },
    ],
    password: [
      {
        required: true,
        message: t("validate.required"),
      },
      {
        min: 8,
        message: t("validate.min"),
      },
    ],
    confirmPassword: [
      {
        required: true,
        message: t("validate.required"),
      },
      {
        min: 8,
        message: t("validate.min"),
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(t("validate.notMatch")));
        },
      }),
    ],
  };

  return (
    <Modal
      hideCloseBtn
      open={open}
      onClose={onClose}
      title={t("header.createAdmin.title")}
    >
      <Form
        name="create-user"
        className="mt-4"
        layout="vertical"
        onFinish={onConfirm}
        validateMessages={false}
      >
        <Form.Item
          className="mb-2"
          name="user_name"
          label={t("header.createAdmin.username")}
          rules={rules.username}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="mb-2"
          name="email"
          label={t("header.createAdmin.email")}
          rules={rules.email}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="mb-2"
          name="password"
          label={t("header.createAdmin.password")}
          rules={rules.password}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          className="mb-6"
          name="password_confirmation"
          label={t("header.createAdmin.confirmPassword")}
          dependencies={["password"]}
          hasFeedback
          rules={rules.confirmPassword}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className="mb-0 flex justify-center">
          <Button
            type="primary"
            shape="round"
            className="login-form-button !w-48 bg-gray-300 font-bold text-base hover:!bg-gray-300"
            onClick={onClose}
          >
            {t("header.createAdmin.cancel")}
          </Button>
        </Form.Item>
        <Form.Item className="mb-0 mt-2 flex justify-center">
          <Button
            type="primary"
            htmlType="submit"
            shape="round"
            className="login-form-button !w-48 bg-secondary font-bold text-base hover:!bg-secondary"
          >
            {t("header.createAdmin.confirm")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateAdminModal;
