import { Spin, Divider } from "antd";
import { assetImages } from "utils/constant";
import UserDetailSider from "../Sider";
import { useMemo } from "react";
import { formatDate } from "utils/commonFunction";
import { useNavigate } from "react-router-dom";
import { getImageURI } from "utils/getImageURI";
import { useTranslation } from "react-i18next";

const BasicInformation = ({ basicInfo, userId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const usersGoBack = () => {
    navigate("/users");
  };

  const fields = useMemo(() => {
    if (basicInfo) {
      return [
        {
          title: t("users.userDetail.userName"),
          value: basicInfo.user_name,
        },
        {
          title: t("users.userDetail.mail"),
          value: basicInfo.email,
        },
        {
          title: t("users.userDetail.dob"),
          value: formatDate(basicInfo.date_of_birth),
        },
        {
          title: t("users.userDetail.registrationDate"),
          value: formatDate(basicInfo.created_at),
        },
        {
          title: t("users.userDetail.paymentInfo"),
          value:
            basicInfo.payment_information === "apple_store"
              ? t("users.userDetail.appleStore")
              : basicInfo.payment_information === "play_store"
              ? t("users.userDetail.playStore")
              : "",
        },
        {
          title: t("users.userDetail.status"),
          value: basicInfo.active
            ? t("users.userDetail.statusInUse")
            : t("users.userDetail.statusPause"),
        },
      ];
    }

    return [];
  }, [basicInfo, t]);

  return (
    <div className="w-full h-full max-h-full flex max-w-7xl flex-col">
      <div
        onClick={usersGoBack}
        className="flex justify-between mb-4 cursor-pointer"
      >
        <img src={assetImages.rightArrow} alt="<" width={48} />
      </div>
      <div className="flex-1">
        <div className="bg-white w-full h-full rounded-3xl py-12">
          <div className="flex px-12">
            <span className="text-primary capitalize text-xl font-inter font-bold">
              {t("users.title")}
            </span>
            <span className="mx-3 text-primary capitalize text-xl font-inter font-bold">
              {">"}
            </span>
            <span className="text-primary capitalize text-xl font-inter font-bold mr-8">
              {`${basicInfo?.user_name || ""}`}
            </span>
          </div>
          {Object.keys(basicInfo).length <= 0 && (
            <div className="flex items-center justify-center w-full h-full">
              <Spin size="large">
                <div className="content" />
              </Spin>
            </div>
          )}
          {Object.keys(basicInfo).length > 0 && (
            <div className="flex w-full h-full py-4 px-8">
              <div className="mt-2">
                <UserDetailSider userId={userId} />
              </div>
              <div className="flex flex-1 items-center justify-center bg-gray-200 ml-4 rounded-xl p-12">
                <div className="w-full h-full bg-white flex flex-1 flex-col items-center justify-center">
                  <img
                    className="mb-4"
                    src={
                      basicInfo.avatar
                        ? getImageURI(basicInfo.avatar)
                        : assetImages.defaultAvatar
                    }
                    alt="avatar"
                    width={80}
                  />
                  {fields.map((field, index) => (
                    <div className="w-4/5 mb-2">
                      <div className="flex w-full justify-between items-center">
                        <span className="font-inter font-medium text-base text-primary w-1/2">
                          {field.title}
                        </span>
                        <span className="font-inter font-medium text-base text-primary w-1/2">
                          {field.value}
                        </span>
                      </div>
                      {index < fields.length - 1 && (
                        <Divider className="my-2 !border-gray-700" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
