export const storage = {
  ACCESS_TOKEN: "ACCESS_TOKEN",
  REFRESH_TOKEN: "REFRESH_TOKEN",
};

export const apiPrefix = "/api/v1";

export const apiUrls = {
  users: "admin/users",
  getUserBasicInformation: "basic_information",
  getUserActivities: "activities",
  getUserMemberships: "memberships",
  getUserTransmissionStatus: "membership_transmission_status",
  getUserAffiliatedMembers: "affiliated_members",
  getUserPaymentHistory: "payment_histories",
  getAdminProfile: "admin/my_info",
  login: "admin/login",
  refreshToken: "admin/refresh_token",
  admins: "admin/admins",
  getContacts: "admin/contacts",
  replyContact: "reply",
  uploadFiles: "upload",
};

export const assetImages = {
  addUser: "/assets/images/add-user.svg",
  enRichLogo: "/assets/logos/enrich.svg",
  rightArrow: "/assets/images/right-arrow.svg",
  defaultAvatar: "/assets/images/avatar.svg",
  closeIcon: "/assets/images/clear.svg",
};

export const maxFileSize = 5 * 1024 * 1024; // 5 MB

export const indexPageUrl = "/users";

export const defaultPerPage = 30;
