import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storage } from "utils/constant";
import { AuthService } from "services/AuthService";
import handleError from "utils/handleError";

export const initialAuthState = {
  data: {
    [storage.ACCESS_TOKEN]: null,
    [storage.REFRESH_TOKEN]: null,
    profile: null,
  },
  meta: {
    loading: false,
    error: null,
  },
};

export const loginActionCenter = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkApi) => {
    try {
      const { data: token } = await AuthService.login({ email, password });

      return token;
    } catch (err) {
      handleError(err);
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const refreshTokenActionCenter = createAsyncThunk(
  "auth/refresh",
  async ({ refresh_token }, thunkApi) => {
    try {
      const { data: token } = await AuthService.refreshToken({ refresh_token });
      const { data: user } = await AuthService.adminInfo({
        access_token: token.access_token,
      });

      return { ...token, ...user };
    } catch (err) {
      handleError(err);
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getAdminProfileActionCenter = createAsyncThunk(
  "auth/profile",
  async ({ access_token }, thunkApi) => {
    try {
      const { data: user } = await AuthService.adminInfo({ access_token });
      return user;
    } catch (err) {
      handleError(err);
      return thunkApi.rejectWithValue(err);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    logoutActionCenter: (state) => {
      state.data = {
        [storage.ACCESS_TOKEN]: null,
        [storage.REFRESH_TOKEN]: null,
        profile: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginActionCenter.fulfilled, (state, { payload }) => {
      state.meta.loading = false;
      state.data = {
        [storage.ACCESS_TOKEN]: payload.access_token,
        [storage.REFRESH_TOKEN]: payload.refresh_token,
      };
    });
    builder.addCase(loginActionCenter.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = null;
    });
    builder.addCase(loginActionCenter.rejected, (state, action) => {
      state.meta.loading = false;
      state.meta.error = action.error;
    });
    // Get Admin Profile
    builder.addCase(
      getAdminProfileActionCenter.fulfilled,
      (state, { payload }) => {
        state.meta.loading = false;
        state.data = {
          ...state.data,
          profile: payload.admin,
        };
      }
    );
    builder.addCase(getAdminProfileActionCenter.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = null;
    });
    builder.addCase(getAdminProfileActionCenter.rejected, (state, action) => {
      state.meta.loading = false;
      state.meta.error = action.error;
    });
  },
});

export const { logoutActionCenter } = authSlice.actions;
export default authSlice.reducer;
