import React from "react";
import { Button } from "antd";
import { assetImages } from "utils/constant";

const Modal = ({ open, onClose, children, title, hideCloseBtn }) => {
  return (
    open && (
      <div
        onClick={onClose}
        className="absolute top-0 right-0 bottom-0 left-0 bg-backdrop z-50 m-auto flex items-center justify-center"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="min-w-80 min-h-96 bg-white rounded-lg p-6 relative"
        >
          {title && (
            <div className="flex f">
              <div
                className={`font-inter text-center font-bold text-xl text-primary w-full items-center flex px-3 ${
                  hideCloseBtn && "justify-center"
                }`}
              >
                <p>{title}</p>
              </div>
              {!hideCloseBtn && (
                <Button type="text !p-0 absolute top-5 right-3">
                  <img src={assetImages.closeIcon} width={28} alt="X" />
                </Button>
              )}
            </div>
          )}
          <div>{children}</div>
        </div>
      </div>
    )
  );
};

export default Modal;
