import { useCallback, useEffect, useState } from "react";
import UserService from "services/UserService";
import handleError from "utils/handleError";
import { useParams } from "react-router-dom";
import UserPaymentHistory from "components/Users/UserDetail/PaymentHistory";
import { useAppDispatch, useAppSelector } from "hooks";
import { getUserInformationByIdActionCenter } from "storage/slices/userSlice";

const UserPaymentHistoryPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [metadata, setMetadata] = useState({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const user = useAppSelector((state) => state.user.data.user);

  useEffect(() => {
    async function fetchData(userId) {
      try {
        const { data } = await UserService.getUsersPaymentHistoryById({
          userId,
          page,
          perPage: 50,
        });
        setMetadata(data);
        setLoading(false);
      } catch (err) {
        handleError(err);
      }
    }

    fetchData(id);
  }, [id, page]);

  useEffect(() => {
    async function fetchUserData(userId) {
      try {
        dispatch(getUserInformationByIdActionCenter({ userId }));
      } catch (err) {
        handleError(err);
      }
    }

    if (id !== user.id) {
      fetchUserData(id);
    }
  }, [dispatch, id, user.id]);

  const handleChangePage = useCallback(
    (newPage) => {
      setPage(newPage);
      setLoading(true);
    },
    [setPage]
  );

  return (
    <UserPaymentHistory
      metadata={{
        loading: loading,
        payment_histories: metadata?.payment_histories,
        pagination: metadata.pagination && {
          currentPage: metadata.pagination.page,
          totalCount: metadata.pagination.total_count,
          pageSize: metadata.pagination.per_page,
          onChangePage: handleChangePage,
        },
      }}
      userId={id}
      userName={user.user_name}
    />
  );
};

export default UserPaymentHistoryPage;
