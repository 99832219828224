import { Layout } from "antd";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import AdminHeader from "./Header";
import AdminSider from "./Sider";
import { indexPageUrl } from "utils/constant";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { getAdminProfileActionCenter } from "storage/slices/authSlide";
import { storage } from "utils/constant";
import CreateAdminModal from "./Header/CreateAdminModal";
import UserService from "services/UserService";
import handleError from "utils/handleError";

const AdminLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShowCreateAdmin] = useState(false);
  const dispatch = useAppDispatch();

  const { data } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const redirectCondition =
      location.pathname === "/" && !!indexPageUrl && indexPageUrl !== "/";

    if (
      !data.profile ||
      (data.profile && Object.keys(data.profile).length <= 0)
    ) {
      // Redirect to login page when user don't have token
      navigate("/login");
    } else if (redirectCondition) {
      navigate(indexPageUrl);
    }
  }, [navigate, data, location]);

  useEffect(() => {
    const handleRefresh = (event) => {
      if (event.type === "beforeunload" && !!data[storage.ACCESS_TOKEN]) {
        dispatch(
          getAdminProfileActionCenter({
            access_token: data[storage.ACCESS_TOKEN],
          })
        );
      }
    };

    window.addEventListener("beforeunload", handleRefresh);
    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, [dispatch, data]);

  const handleToggleAdminModal = () => {
    // TODO: Block this code; it's out of scope.
    // setShowCreateAdmin((prevState) => !prevState);
  };

  const handleCreateAccount = async (account) => {
    try {
      await UserService.createAdmins(account);
      handleToggleAdminModal();
      //TODO: notify
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Layout className="w-svw h-svh">
      <Layout.Header className="h-16 w-full p-0 bg-white border-b border-b-gray-500">
        <AdminHeader onCreateAdminAccount={handleToggleAdminModal} />
      </Layout.Header>
      <Layout>
        <Layout>
          <Layout.Sider className="!w-60 !max-w-60 !min-w-60 !bg-white">
            <AdminSider />
          </Layout.Sider>
          <Layout.Content className="bg-gray-200 flex justify-center items-center p-10 max-w-full relative">
            <Outlet />
            <CreateAdminModal
              open={show}
              onClose={handleToggleAdminModal}
              onConfirm={handleCreateAccount}
            />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
