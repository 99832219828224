import { apiUrls, defaultPerPage } from "utils/constant";
import ApiService from "./ApiService";

const UserService = {
  async getUsers({ searchKey, page, perPage }) {
    const res = await ApiService();
    return await res.get(apiUrls.users, {
      params: {
        q: {
          user_name_or_id_or_email_cont: searchKey,
        },
        page,
        per_page: perPage || defaultPerPage,
      },
    });
  },

  async getUsersById({ userId }) {
    const res = await ApiService();
    return await res.get(
      `${apiUrls.users}/${userId}/${apiUrls.getUserBasicInformation}`
    );
  },
  async getUsersActivitiesById({ userId, page, perPage }) {
    const res = await ApiService();
    return await res.get(
      `${apiUrls.users}/${userId}/${apiUrls.getUserActivities}`,
      {
        params: {
          page,
          per_page: perPage || defaultPerPage,
        },
      }
    );
  },

  async getUsersPaymentHistoryById({ userId, page, perPage }) {
    const res = await ApiService();
    return await res.get(
      `${apiUrls.users}/${userId}/${apiUrls.getUserPaymentHistory}`,
      {
        params: {
          page,
          per_page: perPage || defaultPerPage,
        },
      }
    );
  },

  async getUsersMembershipsById({ userId, page, perPage }) {
    const res = await ApiService();
    return await res.get(
      `${apiUrls.users}/${userId}/${apiUrls.getUserMemberships}`,
      {
        params: {
          page,
          per_page: perPage || defaultPerPage,
        },
      }
    );
  },

  async getUsersAffiliatedMembersById({ userId, page, perPage }) {
    const res = await ApiService();
    return await res.get(
      `${apiUrls.users}/${userId}/${apiUrls.getUserAffiliatedMembers}`,
      {
        params: {
          page,
          per_page: perPage || defaultPerPage,
        },
      }
    );
  },

  async createUsers({
    user_name,
    email,
    date_of_birth,
    password,
    password_confirmation,
  }) {
    const res = await ApiService();
    return await res.post(apiUrls.users, {
      user_name,
      email,
      date_of_birth,
      password,
      password_confirmation,
    });
  },

  async createAdmins(account) {
    const res = await ApiService();
    return await res.post(apiUrls.admins, {
      ...account,
    });
  },

  async getAdminProfile() {
    const res = await ApiService();
    return await res.get(`${apiUrls.getAdminProfile}`);
  },

  async getUsersTransmissionStatusById({ userId }) {
    const res = await ApiService();
    return await res.get(
      `${apiUrls.users}/${userId}/${apiUrls.getUserTransmissionStatus}`
    );
  },
};

export default UserService;
