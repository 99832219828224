import { Button, Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { assetImages } from "utils/constant";
import { useTranslation } from "react-i18next";
import { emailValidator } from "utils/emailValidator";

const LoginPresentation = ({ onLogin }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <img className="pb-10" src={assetImages.enRichLogo} alt="enrich-logo" />
      <Form
        name="normal_login"
        className="login-form w-72"
        initialValues={{ remember: true }}
        onFinish={onLogin}
      >
        <Form.Item
          name="email"
          rules={[
            {
              validator: (_, value) => {
                return emailValidator(value, t);
              },
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t("login.passwordEmpty") }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item className=" flex justify-center">
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button !w-40 bg-secondary font-bold text-base"
          >
            {t("login.button")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPresentation;
