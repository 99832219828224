import "./App.scss";
import "./lang/i18n";

import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import AdminLayout from "components/common/AdminLayout";
import LoginLayout from "components/common/LoginLayout";
import ErrorLayout from "components/common/ErrorLayout";
import LoginPage from "pages/login";
import UserManagementPage from "pages/users";
import BasicInformationPage from "pages/users/userDetail/basicInformation";
import UserActivitiesPage from "pages/users/userDetail/activities";
import UserMembershipPage from "pages/users/userDetail/membership";
import UserPaymentHistoryPage from "pages/users/userDetail/paymentHistory";
import ContactsPage from "pages/contacts";
import ContactDetailPage from "pages/contacts/detail";



function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <React.Fragment>
        <Route
          path="/"
          element={<AdminLayout />}
          errorElement={<ErrorLayout />}
        >
          <Route path="/users" element={<UserManagementPage />} />
          <Route path="/users/:id" element={<BasicInformationPage />} />
          <Route
            path="/users/:id/activities"
            element={<UserActivitiesPage />}
          />
          <Route
            path="/users/:id/membership"
            element={<UserMembershipPage />}
          />
          <Route
            path="/users/:id/payment-history"
            element={<UserPaymentHistoryPage />}
          />
          {/* TODO: Block this code; it's out of scope. */}
          {/* <Route path="/contacts" element={<ContactsPage />} /> */}
          {/* <Route path="/contacts/:id" element={<ContactDetailPage />} /> */}
        </Route>
        <Route
          path="/login"
          element={<LoginLayout />}
          errorElement={<ErrorLayout />}
        >
          <Route index element={<LoginPage />} />
        </Route>
      </React.Fragment>
    )
  );

  return (
    <div className="app-root">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
