import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "services/UserService";
import handleError from "utils/handleError";

export const initialCountState = {
  data: {
    user: {},
  },
  meta: {
    loading: false,
    error: null,
  },
};

export const getUserInformationByIdActionCenter = createAsyncThunk(
  "example/asyncCount",
  async ({ userId }, thunkApi) => {
    try {
      const { data } = await UserService.getUsersById({ userId });

      return data;
    } catch (err) {
      handleError(`IDが${userId}のユーザーが見つかりませんでした。`);
      return thunkApi.rejectWithValue(err);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: initialCountState,
  extraReducers: (builder) => {
    builder.addCase(
      getUserInformationByIdActionCenter.fulfilled,
      (state, action) => {
        state.meta.loading = false;
        state.data.user = action.payload.user;
      }
    );
    builder.addCase(getUserInformationByIdActionCenter.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = null;
    });
    builder.addCase(
      getUserInformationByIdActionCenter.rejected,
      (state, action) => {
        state.meta.loading = false;
        state.meta.error = action.error;
      }
    );
  },
});
export default userSlice.reducer;
