import { useCallback, useEffect } from "react";
import LoginPresentation from "components/Login";
import { indexPageUrl, storage } from "utils/constant";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  getAdminProfileActionCenter,
  loginActionCenter,
} from "storage/slices/authSlide";
import handleError from "utils/handleError";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const access_token = data[storage.ACCESS_TOKEN];
    if (!data.profile && access_token) {
      dispatch(getAdminProfileActionCenter({ access_token }));
    }
    //Go to login page when user have access_token
    if (data.profile && location.pathname === "/login") {
      navigate(indexPageUrl || "/");
    }
  }, [navigate, data, location, dispatch]);

  const handleLogin = useCallback(
    async (account) => {
      try {
        dispatch(loginActionCenter(account));
      } catch (err) {
        handleError(err);
      }
    },
    [dispatch]
  );

  return <LoginPresentation onLogin={handleLogin} />;
};

export default LoginPage;
