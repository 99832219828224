import { Table, Pagination, Input, Button } from "antd";
import { assetImages } from "utils/constant";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/commonFunction";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Users = ({ users, loading, pagination, onSearch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");

  const userManagementColumns = [
    { label: "ID", key: "id", width: 80 },
    { label: t("users.username"), key: "user_name", width: 240 },
    { label: t("users.email"), key: "email" },
    {
      label: t("users.registrationDate"),
      key: "created_at",
      width: 200,
      render: (value) => <span>{formatDate(value)}</span>,
    },
    {
      label: t("users.status"),
      key: "active",
      width: 200,
      render(value) {
        return (
          <span>{value ? t("users.activate") : t("users.deactivate")}</span>
        );
      },
    },
  ];

  const getTableColumns = () => {
    return userManagementColumns.map((item) => {
      return {
        key: item.key,
        dataIndex: item.key,
        render: item.render,
        width: item.width,
        title: (
          <span className="font-inter font-bold text-base text-primary">
            {item.label}
          </span>
        ),
      };
    });
  };

  const handleSelectRow = (record) => {
    navigate(`${record.id}`);
  };
  const handleChangeSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const handleSearch = (searchValue) => {
    setSearchInput(searchValue.trim());
    onSearch(searchValue);
  };

  return (
    <div className="w-full h-full max-h-full flex flex-col">
      <div className={"flex mb-4 relative"}>
        <h3 className="font-inter font-bold text-2xl text-primary">
          {t("users.title")}
        </h3>
        <Input.Search
          enterButton
          className="w-1/3 absolute left-1/2 -translate-x-2/4	"
          size={"large"}
          placeholder={t("users.inputPlaceholder")}
          value={searchInput}
          onSearch={handleSearch}
          onChange={handleChangeSearchInput}
        />
      </div>
      <div className="flex-1">
        <Table
          className={
            "w-full h-full [&_.ant-table-footer]:rounded-b-lg [&_.ant-table-bordered]:bg-transparent [&_.ant-table-tbody]:bg-white"
          }
          rowKey={"id"}
          rowClassName={"cursor-pointer"}
          bordered
          columns={getTableColumns()}
          dataSource={users}
          loading={loading}
          pagination={false}
          locale={{ emptyText: t("noData") }}
          scroll={{ y: "calc(100svh - 320px)" }}
          onRow={(record) => {
            return {
              onClick: () => {
                handleSelectRow(record);
              },
            };
          }}
          footer={() =>
            pagination && (
              <Pagination
                className="w-full flex justify-center"
                current={pagination.currentPage}
                total={pagination.totalCount}
                pageSize={pagination.pageSize}
                onChange={pagination.onChangePage}
                showSizeChanger={false}
              />
            )
          }
        />
      </div>
    </div>
  );
};

export default Users;
