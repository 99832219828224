import { useEffect, useState } from "react";
import UserService from "services/UserService";
import handleError from "utils/handleError";
import { useParams, useNavigate } from "react-router-dom";
import UserMembership from "components/Users/UserDetail/Membership";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { getUserInformationByIdActionCenter } from "storage/slices/userSlice";

const UserMembershipPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [metadata, setMetadata] = useState({});
  const [status, setStatus] = useState("");
  const [selectedTab, setSelectedTab] = useState("registered-membership");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const user = useAppSelector((state) => state.user.data.user);

  useEffect(() => {
    async function fetchData(userId) {
      try {
        const { data: transmissionStatus } =
          await UserService.getUsersTransmissionStatusById({ userId });
        if (selectedTab === "registered-membership") {
          const { data } = await UserService.getUsersMembershipsById({
            userId,
            page,
          });
          setMetadata(data);
          setLoading(false);
        } else {
          const { data } = await UserService.getUsersAffiliatedMembersById({
            userId,
            page,
          });
          setMetadata(data);
          setLoading(false);
        }
        setStatus(transmissionStatus?.membership_transmission_status || "");
      } catch (err) {
        handleError(err);
      }
    }

    fetchData(id);
  }, [id, page, selectedTab]);

  useEffect(() => {
    async function fetchUserData(userId) {
      try {
        dispatch(getUserInformationByIdActionCenter({ userId }));
      } catch (err) {
        handleError(err);
      }
    }

    if (id !== user.id) {
      fetchUserData(id);
    }
  }, [dispatch, id, user.id]);

  const handleChangePage = useCallback(
    (newPage) => {
      setPage(newPage);
      setLoading(true);
    },
    [setPage]
  );
  const handleSelectMember = useCallback((userId) => {
    navigate(`/users/${userId}`);
  }, []);
  const handleChangeTab = useCallback((newTab) => {
    setSelectedTab(newTab);
    setLoading(true);
    setPage(1);
  }, []);

  return (
    <UserMembership
      userId={id}
      userName={user.user_name}
      status={status}
      members={metadata?.users}
      loading={loading}
      pagination={
        metadata.pagination && {
          currentPage: metadata.pagination.page,
          totalCount: metadata.pagination.total_count,
          pageSize: metadata.pagination.per_page,
          onChangePage: handleChangePage,
        }
      }
      onSelect={handleSelectMember}
      onChangeTab={handleChangeTab}
    />
  );
};

export default UserMembershipPage;
