import axios from "axios";
import { apiUrls, apiPrefix } from "utils/constant";

const API_URL = process.env.REACT_APP_API_URL;
const baseURL = `${API_URL}${apiPrefix}`;

export const AuthService = {
  async login({ email, password }) {
    return await axios.post(`${baseURL}/${apiUrls.login}`, {
      admin: {
        email: email,
        password: password,
      },
    });
  },

  async refreshToken({ refresh_token }) {
    return await axios.post(`${baseURL}/${apiUrls.refreshToken}`, {
      refresh_token: refresh_token,
    });
  },

  async adminInfo({ access_token }) {
    return await axios.get(`${baseURL}/${apiUrls.getAdminProfile}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  },
};
