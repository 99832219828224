import { Avatar, List, Pagination } from "antd";
import { assetImages } from "utils/constant";
import React from "react";
import { useTranslation } from "react-i18next";
import { getImageURI } from "utils/getImageURI";

const MemberList = ({ members, loading, pagination, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={"flex flex-col w-full h-full"}>
      <div className="max-h-[calc(100%_-_48px)] overflow-y-auto	flex-1">
        <List
          itemLayout="horizontal"
          dataSource={members}
          loading={loading}
          locale={{ emptyText: t("noData") }}
          renderItem={(item, index) => (
            <List.Item
              className="!justify-start !px-4 cursor-pointer"
              key={index}
              onClick={() => onClick(item.id)}
            >
              <Avatar
                src={
                  item.avatar
                    ? getImageURI(item.avatar)
                    : assetImages.defaultAvatar
                }
              />
              <span className="ml-4 font-inter font-medium text-primary whitespace-pre">
                {`${t("users.membership.usernameLabel")}     ${item.user_name}`}
              </span>
            </List.Item>
          )}
        />
      </div>
      {pagination && pagination.totalCount > 0 && (
        <Pagination
          className="w-full flex justify-center py-2"
          current={pagination.currentPage}
          total={pagination.totalCount}
          pageSize={pagination.pageSize}
          onChange={pagination.onChangePage}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};

export default MemberList;
