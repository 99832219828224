import { getStoredState, REHYDRATE } from "redux-persist";

export default function crossBrowserListener(store, persistConfig, pathname) {
  return async function () {
    let state = await getStoredState(persistConfig);

    if (pathname === "/login") {
      store.dispatch({
        type: REHYDRATE,
        key: persistConfig.key,
        payload: state,
      });
    }
  };
}
