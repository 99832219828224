import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const initialCountState = {
  data: {
    count: 0,
  },
  meta: {
    loading: false,
    error: null,
  },
};

export const asyncCount = createAsyncThunk(
  "example/asyncCount",
  async ({ count, type }) => {
    return new Promise((res) => {
      setTimeout(res, 1000, { count, type });
    });
  }
);

const countSlice = createSlice({
  name: "count",
  initialState: initialCountState,
  reducers: {
    increment: (state) => {
      state.data.count += 1;
    },
    decrement: (state) => {
      state.data.count -= 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncCount.fulfilled, (state, action) => {
      state.meta.loading = false;
      if (action.payload.type === "increase") {
        state.data.count += action.payload.count;
      } else {
        state.data.count -= action.payload.count;
      }
    });
    builder.addCase(asyncCount.pending, (state, action) => {
      state.meta.loading = true;
      state.meta.error = null;
    });
    builder.addCase(asyncCount.rejected, (state, action) => {
      state.meta.loading = false;
      state.meta.error = action.error;
    });
  },
});
export const { increment, decrement } = countSlice.actions;
export default countSlice.reducer;
