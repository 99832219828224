import { Spin, Table, Pagination } from "antd";
import { assetImages } from "utils/constant";
import UserDetailSider from "../Sider";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/commonFunction";
import { useNavigate } from "react-router-dom";

const UserActivities = ({ metadata, userId, userName }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const usersGoBack = () => {
    navigate("/users");
  };

  const userManagementColumns = [
    {
      label: t("users.activities.dateTime"),
      key: "date_time",
      render: (value) => <span>{formatDate(value)}</span>,
    },
    {
      label: t("users.activities.content"),
      key: "content",
    },
  ];

  const getTableColumns = () => {
    return userManagementColumns.map((item) => {
      return {
        key: item.key,
        dataIndex: item.key,
        render: item.render,
        width: item.width,
        title: (
          <span className="font-inter font-semibold text-base text-gray-450">
            {item.label}
          </span>
        ),
      };
    });
  };

  return (
    <div className="w-full h-full max-h-full max-w-7xl flex flex-col">
      <div
        onClick={usersGoBack}
        className="flex justify-between mb-4 cursor-pointer"
      >
        <img src={assetImages.rightArrow} alt="<" width={48} />
      </div>
      <div className="flex-1">
        <div className="bg-white w-full h-full rounded-3xl p-12 !pl-8">
          <div className="flex pl-4 mb-4">
            <span className="text-primary capitalize text-xl font-inter font-bold">
              {t("users.title")}
            </span>
            <span className="mx-3 text-primary capitalize text-xl font-inter font-bold">
              {">"}
            </span>
            <span className="text-primary capitalize text-xl font-inter font-bold mr-8">
              {`${userName || ""}`}
            </span>
          </div>
          {!metadata && (
            <div className="flex items-center justify-center w-full h-full">
              <Spin size="large">
                <div className="content" />
              </Spin>
            </div>
          )}
          {metadata && (
            <div className="flex flex-1 h-[calc(100%_-_44px)]">
              <div className="mt-2">
                <UserDetailSider userId={userId} />
              </div>
              <div className="flex flex-1 flex-col items-center justify-center bg-gray-200 ml-4 rounded-xl p-12">
                <span className="text-primary capitalize text-xl font-inter font-semibold mb-4 w-full text-start">
                  {t("users.activities.title")}
                </span>
                <div className="w-full flex flex-1 flex-col items-center activity-table-wrapper">
                  <Table
                    className={
                      "[&_.ant-table-footer]:rounded-b-lg [&_.ant-table-bordered]:bg-transparent [&_.ant-table-tbody]:bg-white"
                    }
                    rowKey={(_, index) => `row-${index}`}
                    bordered
                    columns={getTableColumns()}
                    locale={{ emptyText: t("noData") }}
                    dataSource={metadata.activities}
                    loading={metadata.loading}
                    pagination={false}
                    scroll={{ y: "calc(100svh - 570px)" }}
                    footer={() =>
                      metadata.pagination && (
                        <Pagination
                          className="w-full flex justify-center"
                          current={metadata.pagination.currentPage}
                          total={metadata.pagination.totalCount}
                          pageSize={metadata.pagination.pageSize}
                          onChange={metadata.pagination.onChangePage}
                          showSizeChanger={false}
                        />
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserActivities;
