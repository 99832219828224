import { Spin, Tabs } from "antd";
import { assetImages } from "utils/constant";
import UserDetailSider from "../Sider";
import MemberList from "./MemberList";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const UserMembership = ({
  members,
  status,
  pagination,
  loading,
  userId,
  userName,
  onSelect,
  onChangeTab,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const usersGoBack = () => {
    navigate("/users");
  };

  const tabItems = [
    {
      label: (
        <span className="user-membership-tab font-semibold text-base text-gray-900 font-inter">
          {t("users.membership.registeredLabel")}
        </span>
      ),
      key: "registered-membership",
      children: (
        <MemberList
          members={members}
          loading={loading}
          pagination={pagination}
          onClick={onSelect}
        />
      ),
    },
    {
      label: (
        <span className="user-membership-tab font-semibold text-base text-gray-900 font-inter">
          {t("users.membership.affiliatedLabel")}
        </span>
      ),
      key: "affiliated-membership",
      children: (
        <MemberList
          members={members}
          loading={loading}
          pagination={pagination}
          onClick={onSelect}
        />
      ),
    },
  ];

  return (
    <div className="w-full h-full max-h-full max-w-7xl flex flex-col">
      <div
        onClick={usersGoBack}
        className="flex justify-between mb-4 cursor-pointer"
      >
        <img src={assetImages.rightArrow} alt="<" width={48} />
      </div>
      <div className="flex-1 h-[calc(100%_-_64px)]">
        <div className="bg-white w-full h-full rounded-3xl p-12 !pl-8">
          <div className="flex pl-4 mb-4">
            <span className="text-primary capitalize text-xl font-inter font-bold">
              {t("users.title")}
            </span>
            <span className="mx-3 text-primary capitalize text-xl font-inter font-bold">
              {">"}
            </span>
            <span className="text-primary capitalize text-xl font-inter font-bold mr-8">
              {`${userName || ""}`}
            </span>
          </div>
          {!members && (
            <div className="flex items-center justify-center w-full h-full">
              <Spin size="large">
                <div className="content" />
              </Spin>
            </div>
          )}
          {members && (
            <div className="flex flex-1 h-[calc(100%_-_44px)]">
              <div className="mt-2">
                <UserDetailSider userId={userId} />
              </div>
              <div className="flex flex-1 flex-col items-center bg-gray-200 ml-4 rounded-xl p-12">
                <span className="text-primary capitalize text-xl font-inter font-semibold mb-4 w-full text-start">
                  {t("users.membership.title")}
                </span>
                <div className="w-full flex flex-1 flex-col items-center max-h-full">
                  <div className="bg-white w-full px-12 py-3 flex items-center justify-between">
                    <span className="font-semibold text-base text-gray-900 font-inter">
                      {t("users.membership.transmissionLabel")}
                    </span>
                    {/* TODO: API */}
                    <span className="font-inter font-medium text-primary">
                      {t(`users.membership.transmissionStatus.${status}`)}
                    </span>
                  </div>
                  <div className="flex flex-1 w-full mt-2 max-h-[calc(100%-100px)]">
                    <Tabs
                      className="user-membership-tabs-menu flex-1"
                      onChange={(tab) => onChangeTab(tab)}
                      type="card"
                      items={tabItems}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserMembership;
