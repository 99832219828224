import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const UserDetailSider = ({ userId }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const items = useMemo(() => {
    const sideBars = [
      {
        name: t("users.sider.basicInformation"),
        url: `/users/${userId}`,
      },
      {
        name: t("users.sider.activities"),
        url: `/users/${userId}/activities`,
      },
      {
        name: t("users.sider.paymentHistory"),
        url: `/users/${userId}/payment-history`,
      },
      {
        name: t("users.sider.membership"),
        url: `/users/${userId}/membership`,
      },
    ];

    return sideBars.map((item) => {
      return {
        key: item.url,
        icon: item.icon,
        label: (
          <Link className="text-base font-inter font-semibold" to={item.url}>
            {item.name}
          </Link>
        ),
      };
    });
  }, [userId, t]);

  return (
    <Menu
      className="!w-full !border-none user-detail-sider"
      mode={"inline"}
      selectedKeys={[location.pathname]}
      items={items}
    />
  );
};

export default UserDetailSider;
